import React from "react";
import { graphql } from "gatsby";
import CaseStudy from "../components/case-study";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import PrevNext from "../components/prevnext";

export const query = graphql`
query CaseStudyTemplateQuery($id: String!) {
    sampleCaseStudy: sanityCaseStudies(id: { eq: $id }) {
      id
      publishedAt
      mainImage {
        ...Image
      }
      heroImage {
        ...Image
      }
      title
      slug {
        current
      }
      _rawSummary
      _rawObjective
      _rawStrategy
      _rawOutcome
      _rawKpi
      media {
        mediaType
        mediaImage {
          asset {
            gatsbyImageData
          }
        }
        mediaYoutube {
          url
        }
        mediaVimeo {
          url
        }
        mediaInstagram {
          url
        }
        mediaTikTok {
          url
        }
        mediaOther {
          url
        }
        videoRatio
      }
      relatedWork {
        work {
          title
          mainImage {
            ...Image
          }
          slug {
            current
          }
          client {
            clientName
          }
          _id
        }
      }
    }
  }   
`;

const CaseStudyTemplate = (props) => {
  const {data} = props;
  const caseStudy = data && data.sampleCaseStudy;
  const pagination = props.pageContext;

  return (
    <Layout>
      {caseStudy && <SEO title={caseStudy.title || "Untitled"} image={caseStudy.heroImage || caseStudy.mainImage} />}
      {caseStudy && <><CaseStudy {...caseStudy} /><PrevNext {...pagination} category="case-studies"/></>}
    </Layout>
  );
};

export default CaseStudyTemplate;
