import React from "react";
import {Link} from "gatsby";
import PageParallax from "./page-parallax";
import BlockContent from "./block-content";
import {GatsbyImage} from "gatsby-plugin-image";
import ResponsiveEmbed from "react-responsive-embed";
import {InstagramEmbed, TikTokEmbed} from "react-social-media-embed";
import Masonry from "react-masonry-css";
import {motion} from "framer-motion";
import {buildImageObj} from "../lib/helpers";
import {imageUrlFor} from "../lib/image-url";

function CaseStudy(props) {
    const {
        _rawSummary,
        _rawObjective,
        _rawStrategy,
        _rawOutcome,
        _rawKpi,
        title,
        mainImage,
        heroImage,
        media,
        relatedWork
    } = props;

    const breakpointColumnsObj = {
        default: 2,
        1280: 1
    };

    const transition = {
        duration: 0.6,
        ease: [0.43, 0.13, 0.23, 0.96]
    };

    return (
        <>
            <PageParallax image={heroImage ? heroImage : mainImage} title={title}/>
            <section id="case-study-info">
                <div
                    className="container mx-auto relative py-3 my-10 px-5 block-content bg-secondary">
                    <div className="grid lg:grid-cols-2 gap-y-8 lg:gap-x-8 lg:gap-y-0">

                        <div>
                            {_rawSummary && <div className="mb-10">
                               <BlockContent blocks={_rawSummary || []}/></div>}
                            {_rawObjective && <div className="mb-10">
                                <h4 className="uppercase">Objective</h4><BlockContent blocks={_rawObjective || []}/></div>}
                            {_rawStrategy && <div className="mb-10">
                                <h4 className="uppercase">Strategy</h4><BlockContent blocks={_rawStrategy || []}/></div>}
                            {_rawOutcome && <div className="mb-10">
                                <h4 className="uppercase">Outcome</h4><BlockContent blocks={_rawOutcome || []}/>
                            </div>}
                            {_rawKpi && <div>
                                <h4 className="uppercase">KPI's</h4><BlockContent blocks={_rawKpi || []}/>
                            </div>}
                        </div>

                        <Masonry
                            breakpointCols={breakpointColumnsObj}
                            className="masonry-grid xl:ml-2"
                            columnClassName="masonry-grid_column xl:pl-2">
                            {media.map((node, i) => (
                                <React.Fragment key={i}>
                                    {node.mediaType === "image" && node.mediaImage && <div key={i} className="pb-2 break-inside">
                                        <GatsbyImage
                                            image={node.mediaImage.asset.gatsbyImageData}
                                            alt="Case Study Image"/></div>}

                                    {node.mediaType === "youtube" && node.mediaYoutube && <div key={i} className="pb-2 break-inside">
                                        <ResponsiveEmbed
                                            allowFullScreen
                                            src={node.mediaYoutube.url}
                                            ratio={node.videoRatio
                                            ? `${node.videoRatio}`
                                            : '16:9'}/></div>}

                                    {node.mediaType === "vimeo" && node.mediaVimeo && <div key={i} className="pb-2 break-inside">
                                        <ResponsiveEmbed
                                            allowFullScreen
                                            src={node.mediaVimeo.url}
                                            ratio={node.videoRatio
                                            ? `${node.videoRatio}`
                                            : '16:9'}/></div>}

                                    {node.mediaType === "instagram" && node.mediaInstagram && <div key={i} className="flex justify-center pb-2 break-inside">
                                        <InstagramEmbed url={node.mediaInstagram.url} style={{maxWidth:550}} width="100%"/></div>}

                                    {node.mediaType === "tiktok" && node.mediaTikTok && <div key={i} className="flex justify-center pb-2 break-inside" align="center">
                                        <TikTokEmbed url={node.mediaTikTok.url} width={325}/></div>}

                                    {node.mediaType === "other" && node.mediaOther && <div key={i} className="pb-2 break-inside">
                                        <ResponsiveEmbed
                                            allowFullScreen
                                            src={node.mediaOther.url}
                                            ratio={node.videoRatio
                                            ? `${node.videoRatio}`
                                            : '16:9'}/></div>}
                                </React.Fragment>
                            ))}
                        </Masonry>
                    </div>
                </div>
            </section>

            {relatedWork.length !== 0 && <section id="related-work" className="px-5 py-10 bg-white">
                <h3 className="text-center text-3xl mb-5">Related Projects</h3>
                <div className="flex flex-row flex-wrap justify-center items-center">
                    {relatedWork.map((node, i) => (
                            
                        <div key={i} className="w-full h-full sm:h-72 sm:w-72 sm:px-2 py-2">
                            <Link to={`/work/${node.work.slug.current}`}>
                                <div className="preview">
                                    <div className="preview-img">
                                        <div className="preview-txt">
                                            <h3 className="preview-client font-bold text-lg">{node.work.client.clientName}</h3>
                                            <h4 className="preview-title">{node.work.title}</h4>
                                        </div>
                                        <motion.div
                                            className="project-img zoom"
                                            style={{
                                            backgroundImage: `url(${imageUrlFor(buildImageObj(node.work.mainImage))
                                                .auto('format')
                                                .width(750)
                                                .height(750)
                                                .url()})`
                                        }}
                                            transition={transition}/>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </section>}
        </>
    );
}

export default CaseStudy;